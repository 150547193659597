import React, { useState, useEffect } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import "./styles/App.scss";

const MONSTERS = [
  { id: 1, count: 0, name: "スライム" },
  { id: 2, count: 0, name: "ドラキー" },
  { id: 3, count: 0, name: "ガップリン" },
  { id: 4, count: 0, name: "おおねずみ" },
  { id: 5, count: 0, name: "プリズニャン" },
  { id: 6, count: 0, name: "まほうつかい" },
  { id: 7, count: 0, name: "ゴースト" },
  { id: 8, count: 0, name: "おばけキャンドル" },
  { id: 9, count: 0, name: "ホイミスライム" },
  { id: 10, count: 0, name: "ダンスニードル" },
  { id: 11, count: 0, name: "スライムナイト" },
  { id: 12, count: 0, name: "ブラウニー" },
  { id: 13, count: 0, name: "エビルアップル" },
  { id: 14, count: 0, name: "ばくだんベビー" },
  { id: 15, count: 0, name: "メタルスライム" },
  { id: 16, count: 0, name: "おばけキノコ" },
  { id: 17, count: 0, name: "くさったしたい" },
  { id: 18, count: 0, name: "イエティ" },
  { id: 19, count: 0, name: "ドラゴンキッズ" },
  { id: 20, count: 0, name: "クックルー" },
  { id: 21, count: 0, name: "エンプーサ" },
  { id: 22, count: 0, name: "ビックアイ" },
  { id: 23, count: 0, name: "さまようよろい" },
  { id: 24, count: 0, name: "ドロヌーバ" },
  { id: 25, count: 0, name: "はぐれメタル" },
  { id: 26, count: 0, name: "ミステリドール" },
  { id: 27, count: 0, name: "パペットマン" },
  { id: 28, count: 0, name: "キメラ" },
  { id: 29, count: 0, name: "ばくだんいわ" },
  { id: 30, count: 0, name: "おどるほうせき" },
  { id: 31, count: 0, name: "ベホマスライム" },
  { id: 32, count: 0, name: "キングスライム" },
  { id: 33, count: 0, name: "ほのおのせんし" },
  { id: 34, count: 0, name: "ドラゴンマッド" },
  { id: 35, count: 0, name: "ミニデーモン" },
  { id: 36, count: 0, name: "メッサーラ" },
  { id: 37, count: 0, name: "オークキング" },
  { id: 38, count: 0, name: "ホークマン" },
  { id: 39, count: 0, name: "アームライオン" },
  { id: 40, count: 0, name: "エリミネーター" },
  { id: 41, count: 0, name: "ゴーレム" },
  { id: 42, count: 0, name: "ケンタラウス" },
  { id: 43, count: 0, name: "ブリザードマン" },
  { id: 44, count: 0, name: "スライムベホマズン" },
  { id: 45, count: 0, name: "ソルジャーブル" },
  { id: 46, count: 0, name: "サターンヘルム" },
  { id: 47, count: 0, name: "メガザルロック" },
  { id: 48, count: 0, name: "アンクルホーン" },
  { id: 49, count: 0, name: "ホークブリザード" },
  { id: 50, count: 0, name: "シュプリンガー" },
  { id: 51, count: 0, name: "エビルマスター" },
  { id: 52, count: 0, name: "アークデーモン" },
  { id: 53, count: 0, name: "グレイトドラゴン" },
  { id: 54, count: 0, name: "キラーマシン" },
  { id: 55, count: 0, name: "ライオネック" },
  { id: 56, count: 0, name: "ギガンテス" },
  { id: 57, count: 0, name: "ヘルバトラー" },
  { id: 58, count: 0, name: "しびれくらげ" },
  { id: 59, count: 0, name: "ネーレウス" },
  { id: 60, count: 0, name: "コロヒーロー" },
  { id: 61, count: 0, name: "コロファイター" },
  { id: 62, count: 0, name: "コロプリースト" },
  { id: 63, count: 0, name: "コロマージ" },
  { id: 64, count: 0, name: "プチヒーロー" },
  { id: 65, count: 0, name: "プチファイター" },
  { id: 66, count: 0, name: "プチプリースト" },
  { id: 67, count: 0, name: "プチマージ" },
  { id: 68, count: 0, name: "キラーパンサー" },
  { id: 69, count: 0, name: "ザイル" },
  { id: 70, count: 0, name: "プチターク" },
  { id: 71, count: 0, name: "プオーン" }
]

const monsterCount = MONSTERS.length;

const App = () => {
  const [monsters, setMonsters] = useState(MONSTERS);

  const handleClick = id => {
    setMonsters(
      monsters.map(monster =>
        monster.id === id
          ? { ...monster, count: (monster.count + 1) % 4 }
          : monster
      )
    );
  };

  const kindsMonster = monsters.filter(monster => monster.count > 0).length;
  const totalMonster = monsters.reduce((sum, monster) => sum + monster.count, 0);

  //ローカルストレージに取得
  useEffect(() => {
    const storedMonsters = localStorage.getItem("monsters");
    if (storedMonsters) {
      setMonsters(JSON.parse(storedMonsters));
    }
  }, []);

  //ローカルストレージに保存
  useEffect(() => {
    localStorage.setItem("monsters", JSON.stringify(monsters));
  }, [monsters]);

  function handleDeleteButton() {
    if (window.confirm('アプリデータを削除いたします。')) {
      localStorage.removeItem('monsters');
      setMonsters(MONSTERS);
    }
  }

  return (
    <div className="App">
      <Header />
      <div className="wrapper">
        <p className="leadText">仲間モンスターを記録して冒険を楽しもう！</p>

        {totalMonster >= 76 && (
          <div className="caution">
            <p>モンスターが上限に達しています！</p>
          </div>
        )}

        <dl className="monsterBoxes">
          <div className="box">
            <dt>仲間モンスターの種類</dt>
            <dd><span className="lText">{kindsMonster}</span><span className="mText"> / {monsterCount}</span><span className="sText">種類</span></dd>
          </div>
          <div className="box">
            <dt>仲間モンスター総数</dt>
            <dd><span className="lText">{totalMonster}</span><span className="mText"> / 76</span><span className="sText">匹</span></dd>
          </div>
        </dl>
        <ul className="monsterList">
          {monsters.map(({ id, count, name }) => (
            <li key={id} className={`item ${count === 3 ? "max" : ""} ${count > 0 ? "get" : ""}`}>
              <button type="button" className="monsterItem" onClick={() => handleClick(id)}>
                <span className="id">{id}</span>
                <span className="name">{name}</span>
                <span className="number">{"🌟".repeat(count)}</span>
                <span className="complete">complete!</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="buttonUnit">
          <button type="button" className="deleteButton" onClick={handleDeleteButton}>データリセット</button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;